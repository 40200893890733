import * as Sentry from "@sentry/nextjs";
import Cookies from "js-cookie";
import { usePathname, useSearchParams } from "next/navigation";
import React from "react";
import { appleAuthHelpers, useScript } from "react-apple-signin-auth";
import { useSession } from "ui/containers/session";
import { captureEvent } from "ui/lib/captureEvent";
import {
  AUTH_REDIRECT_COOKIE_KEY,
  AUTH_REDIRECT_SEARCH_PARAMS_COOKIE_KEY,
} from "ui/lib/constants";

export const useAuth = () => {
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { setSession } = useSession();

  const [pending, setPending] = React.useState(false);
  const [error, setError] = React.useState();

  const authOptions = {
    clientId: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
    redirectURI: `${process.env.NEXT_PUBLIC_DOMAIN}/_/api/auth/callback/apple`,
    scope: "name email",
    usePopup: false,
    responseMode: "form_post",
    responseType: "code id_token",
    nonce: "nonce",
  };

  const handleLogin = async (redirectURL, options = {}) => {
    try {
      const cookie = typeof redirectURL === "string" ? redirectURL : pathname;
      Cookies.set(AUTH_REDIRECT_COOKIE_KEY, cookie || pathname);
      Cookies.set(
        AUTH_REDIRECT_SEARCH_PARAMS_COOKIE_KEY,
        searchParams.toString() || ""
      );

      captureEvent({
        action: "Sign_In_With_Apple_Clicked",
        label: "Sign In With Apple Clicked",
        category: "auth",
      });
      setPending(true);
      setTimeout(async () => {
        await appleAuthHelpers.signIn({
          authOptions: { ...authOptions, ...options },
          onError: (onError) => setError(onError),
        });
        setPending(false);
      }, 100);
    } catch (error) {
      console.error(error);
      setPending(false);
      setError(error);
      captureEvent({
        action: "Sign_In_With_Apple_Failed",
        label: "Sign In With Apple FAiled",
        category: "auth",
      });
      Sentry.captureException(error);
    }
  };

  const handleLogout = (e) => {
    e?.preventDefault?.();
    setSession("");
    window.location.reload();
  };

  return {
    handleLogin,
    handleLogout,
    pending,
    error,
  };
};
