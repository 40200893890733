"use client";

import { InfoCircledIcon } from "@radix-ui/react-icons";
import { useRouter } from "next/router";
import { useCurrentUser } from "queries/current-user";
import React from "react";
import { useSession } from "ui/containers/session";

export const AuthBanner = () => {
  const { session } = useSession();
  const { isReady } = useRouter();
  const { currentUser, isAdminOrCoach } = useCurrentUser();

  if (!session) return null;
  if (!isReady) return null;
  if (!!currentUser && isAdminOrCoach) return null;
  return (
    <div className="fixed text-sm md:text-base border-b flex center space-x-2 top-0 inset-x-0 z-50 bg-background text-center text-foreground py-2 px-4">
      <InfoCircledIcon className="min-w-[1rem] w-4 h-4" />
      <span>
        The account you logged in with is not a Ladder Admin or Coach. Please
        log in using a different account.
      </span>
    </div>
  );
};
